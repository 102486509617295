@import "~/node_modules/normalize.css/normalize.css";
@import '_colors';
@import '_logo_animation';

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    --space-3xs: clamp(0.25rem, calc(0.23rem + 0.11vw), 0.31rem);
    --space-2xs: clamp(0.50rem, calc(0.46rem + 0.22vw), 0.63rem);
    --space-xs: clamp(0.75rem, calc(0.68rem + 0.33vw), 0.94rem);
    --space-s: clamp(1.00rem, calc(0.91rem + 0.43vw), 1.25rem);
    --space-m: clamp(1.50rem, calc(1.37rem + 0.65vw), 1.88rem);
    --space-l: clamp(2.00rem, calc(1.83rem + 0.87vw), 2.50rem);
    --space-xl: clamp(3.00rem, calc(2.74rem + 1.30vw), 3.75rem);
    --space-2xl: clamp(4.00rem, calc(3.65rem + 1.74vw), 5.00rem);
    --space-3xl: clamp(6.00rem, calc(5.48rem + 2.61vw), 7.50rem);
  
    /* One-up pairs */
    --space-3xs-2xs: clamp(0.25rem, calc(0.12rem + 0.65vw), 0.63rem);
    --space-2xs-xs: clamp(0.50rem, calc(0.35rem + 0.76vw), 0.94rem);
    --space-xs-s: clamp(0.75rem, calc(0.58rem + 0.87vw), 1.25rem);
    --space-s-m: clamp(1.00rem, calc(0.70rem + 1.52vw), 1.88rem);
    --space-m-l: clamp(1.50rem, calc(1.15rem + 1.74vw), 2.50rem);
    --space-l-xl: clamp(2.00rem, calc(1.39rem + 3.04vw), 3.75rem);
    --space-xl-2xl: clamp(3.00rem, calc(2.30rem + 3.48vw), 5.00rem);
    --space-2xl-3xl: clamp(4.00rem, calc(2.78rem + 6.09vw), 7.50rem);
  
    /* Custom pairs */
    --space-s-l: clamp(1.00rem, calc(0.48rem + 2.61vw), 2.50rem);
  }
  

/* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    --step--2: clamp(0.69rem, calc(0.66rem + 0.18vw), 0.80rem);
    --step--1: clamp(0.83rem, calc(0.78rem + 0.29vw), 1.00rem);
    --step-0: clamp(1.00rem, calc(0.91rem + 0.43vw), 1.25rem);
    --step-1: clamp(1.20rem, calc(1.07rem + 0.63vw), 1.56rem);
    --step-2: clamp(1.44rem, calc(1.26rem + 0.89vw), 1.95rem);
    --step-3: clamp(1.73rem, calc(1.48rem + 1.24vw), 2.44rem);
    --step-4: clamp(2.07rem, calc(1.73rem + 1.70vw), 3.05rem);
    --step-5: clamp(2.49rem, calc(2.03rem + 2.31vw), 3.82rem);
  }

:root {
    font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
}

[x-cloak] {
    display: none !important;
}

body {
    background-color: $white;
    color: $black;
    margin: var(--space-xs-s);
    margin-bottom: 50px;
    font-size: var(--step-0);
}

h1 {
    font-size: var(--step-5);
    margin-block-start: var(--space-l);
    margin-block-end: var(--space-l);
}

h2 {
    font-size: var(--step-4);
    margin-block-start: var(--space-3xs-2xs);
    margin-block-end: var(--space-3xs-2xs);
}

h3 {
    font-size: var(--step-3);
    margin-block-start: var(--space-3xs-2xs);
    margin-block-end: var(--space-3xs-2xs);
}

h4 {
    font-size: var(--step-1);
    margin-block-start: var(--space-3xs-2xs);
    margin-block-end: var(--space-3xs-2xs);
}

h5 {
    font-size: var(--step-0);
    font-weight: 300;
    margin-block-start: var(--space-3xs-2xs);
    margin-block-end: var(--space-3xs-2xs);
}

h6 {
    font-size: var(--step-0);
    font-weight: 500;
    margin-block-start: var(--space-3xs-2xs);
    margin-block-end: var(--space-3xs-2xs);
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
    & + p {
        margin-block-start: 0; 
    }
}

a:link {
    color: $primary;
}

a:hover {
    color: darken($primary, 20);
}

a:visited {
    color: $alternate;
}

.container {
    max-width: 1240px;
    min-width: 320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.timespan {
    white-space: nowrap;
    time + time::before {
        content: ' - '
    }
}

.muted {
    color: $muted;
    font-size: 0.9rem;
    font-style: italic;
}

.hero {
    // min-width: 320px;
    font-size: var(--step-5);
    display: inline-block;
    position: relative;
    margin: 0 auto;
    margin-bottom: var(--space-xs-s);

    #logo {
        width: 3em;
        height: 3em;
        position: absolute;
        top: 0;
        left: -1em;
        z-index: -1;
    }

    h1.heading {
        font-size: 1em;
        margin-top: 1.8em;
        text-align: center;
        text-shadow: 0 0 1rem $white;

        span {
            text-decoration: underline;
            text-decoration-style: wavy;
            text-decoration-color: fade-out($accent, 0.5);
            text-decoration-skip-ink: auto;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 48em) {
        flex-direction: column;
    }
}

.intro {
    margin: 0 var(--space-3xs) var(--space-m-l);
    text-align: center;
}

.portrait {
    margin: 0 var(--space-3xs) var(--space-m-l);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: inline-block;
        --factor: 15vw;
        --dimens: clamp(80px, var(--factor), 150px);
        width: var(--dimens);
        height: var(--dimens);
        margin: 10px;
        border: 5px solid fade-out($accent, 0.5);
    }

    h4 {
        white-space: nowrap;
        &::before {
            content: '— ';
        }
    }
}

.experiences {
    margin: var(--space-m) var(--space-xl) 0 var(--space-xl);

    @media only screen and (max-width: 48em) {
        margin: var(--space-m) 0 0 0;
    }

    
}

.job {
    display: flex;
    flex-direction: row;
    gap: var(--space-s-m);

    @media only screen and (max-width: 48em) {
        flex-direction: column;
        gap: 0;
    }

    & + & {
        margin-block-start: var(--space-m-l);
    }

    &--location {
        flex-shrink: 0;

        width: 200px;
        @media only screen and (max-width: 48em) {
            width: auto;
        }

        h5 {
            margin-block-start: 0;
        }
    }

    &--description {
        line-height: 1.2em;
        font-weight: 300;
        strong {
            font-weight: 500;
        }
        h4 {
            margin-block-start: 0;
        }
    }
}

.spacer {
    height: 2px;
    background-color: $black;
    width: 33%;
    min-width: 100px;
    align-self: center;
    margin-block-start: var(--space-l-xl);
    margin-block-end: var(--space-l-xl);
}

.footer {
    gap: 4rem;

    @media only screen and (max-width: 48em) {
        gap: 0;
    }

    &>* {
        flex: 1;
    }

}

.social {
    font-size: 1rem;
    line-height: 1.5;
}

code.pgp {
    font-family: monospace;
    font-size: 0.8rem;
    background-color: $code-bg;
    padding: 2px;
}

.books {
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        gap: 1rem;

        @media only screen and (max-width: 48em) {
            grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        }

        .book {
            &__cover {
                width: 100%;
            }

            &__title {
                margin-top: 0;
            }

            &__author {
                margin-top: 0;
                color: $muted;
            }
        }

    }
}