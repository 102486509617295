@keyframes enter_main {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes enter_accent {
    0% {
        opacity: 0;
        transform: translateX(-200px) translateY(-100px);
    }

    50% {
        transform: translateX(-200px) translateY(-100px);
    }

    60% {
        transform: translateX(0) translateY(-100px);
    }

    75% {
        transform: translateX(0) translateY(-100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
}

@keyframes enter_name {
    0% {
        transform-origin: 43% 0;
        transform: rotateY(100deg);
        opacity: 0;
    }

    100% {
        transform-origin: 43% 0;
        transform: rotateY(0);
        opacity: 1;
    }
}

#logo svg * {
    shape-rendering: crispEdges;
}

#logo svg {
    will-change: transform opacity;

    .s_main {
        animation: enter_main 350ms ease-in forwards;
        opacity: 0;
        animation-delay: 1s;
    }

    .s_accent {
        animation: enter_accent 1s linear forwards;
        opacity: 0;
        animation-delay: 1s;

    }

    .name {
        opacity: 0;
        animation: enter_name 350ms ease-in forwards;
        animation-delay: 2s;
    }
}